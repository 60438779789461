import React from "react";

import "keen-slider/keen-slider.min.css";
import "./src/static/styles/app.scss";

import { PopupProvider } from "@/contexts/PopUp";
import { PageProvider } from "@/contexts/Page";

export const wrapRootElement = ({ element }) => {
  return (
    <PageProvider>
      <PopupProvider>{element}</PopupProvider>
    </PageProvider>
  );
};

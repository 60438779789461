import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { navigate } from "gatsby";

type Language = "es" | "en";

interface PageContextProps {
  type: string;
  slug: string;
  title: string;
  content: string;
  language: Language;
  setType: (type: string) => void;
  setSlug: (slug: string) => void;
  setTitle: (title: string) => void;
  setContent: (content: string) => void;
  updateContext: (
    slug?: string,
    title?: string,
    content?: string,
    type?: string
  ) => void;
  updateLanguageNavigate: (lang: Language) => void;
  updateLanguage: (lang: Language) => void;
}

const PageContext = createContext<PageContextProps | undefined>(undefined);

export const PageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>("es");
  const [content, setContent] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [slug, setSlug] = useState<string>("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedLanguage = sessionStorage.getItem("language") as Language;
      if (savedLanguage) {
        setLanguage(savedLanguage);
      }
    }
  }, []);

  const updateContext = (
    slug?: string,
    title?: string,
    content?: string,
    type?: string
  ) => {
    if (slug !== undefined || slug === "") setSlug(slug);
    if (title !== undefined || title === "") setTitle(title);
    if (content !== undefined || content === "") setContent(content);
    if (type !== undefined || type === "") setType(type);
  };

  const updateLanguageNavigate = (lang: Language) => {
    if (typeof window !== "undefined") {
      const isEnglish = window.location.pathname.startsWith("/en/");
      const path = isEnglish
        ? window.location.pathname.substring(3)
        : window.location.pathname;

      if (lang === "es") {
        navigate(path);
      } else {
        navigate("/en".concat(path));
      }

      setLanguage(lang);
      sessionStorage.setItem("language", lang);
    }
  };

  const updateLanguage = (lang: Language) => {
    if (typeof window !== "undefined") {
      setLanguage(lang);
      sessionStorage.setItem("language", lang);
    }
  };

  return (
    <PageContext.Provider
      value={{
        type,
        slug,
        title,
        content,
        language,
        setType,
        setSlug,
        setTitle,
        setContent,
        updateContext,
        updateLanguage,
        updateLanguageNavigate,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = (): PageContextProps => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("usePageContext must be used within a PageProvider");
  }
  return context;
};
